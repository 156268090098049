import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire';
import {AngularFireFunctionsModule} from '@angular/fire/functions';


const firebaseConfig = {
  apiKey: "AIzaSyCY-WRKqlBGtVCDsVftCMaOBX5ys-z5OBw",
  authDomain: "freeaty-909fc.firebaseapp.com",
  projectId: "freeaty-909fc",
  storageBucket: "freeaty-909fc.appspot.com",
  messagingSenderId: "449962216719",
  appId: "1:449962216719:web:2de0a8ce6043b1062d027a",
  measurementId: "G-4JJDCEVSV8"
};


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,
     IonicModule.forRoot(),
      AppRoutingModule,
      AngularFireModule.initializeApp(firebaseConfig),
      AngularFireFunctionsModule],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    NavParams
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
